import React from "react";
// import { Calendar, Badge } from "antd";
// import type { BadgeProps, CalendarProps } from "antd";
import Container from "react-bootstrap/Container";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/pt-br";
import MechanicTestCalendar from "../MechanicTestCalendar";
dayjs.locale("pt-br");

// const getListData = (value: Dayjs) => {
//   let listData: { type: string; content: string }[] = []; // Specify the type of listData
//   switch (value.date()) {
//     case 8:
//       listData = [
//         { type: "warning", content: "This is warning event." },
//         { type: "success", content: "This is usual event." },
//       ];
//       break;
//     case 10:
//       listData = [
//         { type: "warning", content: "This is warning event." },
//         { type: "success", content: "This is usual event." },
//         { type: "error", content: "This is error event." },
//       ];
//       break;
//     case 15:
//       listData = [
//         { type: "warning", content: "This is warning event" },
//         { type: "success", content: "This is very long usual event......" },
//         { type: "error", content: "This is error event 1." },
//         { type: "error", content: "This is error event 2." },
//         { type: "error", content: "This is error event 3." },
//         { type: "error", content: "This is error event 4." },
//       ];
//       break;
//     default:
//   }
//   return listData || [];
// };

// const getMonthData = (value: Dayjs) => {
//   if (value.month() === 8) {
//     return 1394;
//   }
// };

const Scheduler = () => {
  // const monthCellRender = (value: Dayjs) => {
  //   const num = getMonthData(value);
  //   return num ? (
  //     <div className="notes-month">
  //       <section>{num}</section>
  //       <span>Backlog number</span>
  //     </div>
  //   ) : null;
  // };
  //
  // const dateCellRender = (value: Dayjs) => {
  //   const listData = getListData(value);
  //   return (
  //     <ul className="events">
  //       {listData.map((item) => (
  //         <li key={item.content}>
  //           <Badge
  //             status={item.type as BadgeProps["status"]}
  //             text={item.content}
  //           />
  //         </li>
  //       ))}
  //     </ul>
  //   );
  // };

  // const cellRender: CalendarProps<Dayjs>["cellRender"] = (current, info) => {
  //   if (info.type === "date") return dateCellRender(current);
  //   if (info.type === "month") return monthCellRender(current);
  //   return info.originNode;
  // };
  return (
    <Container className="h-100 p-0 d-flex align-items-center">
      <MechanicTestCalendar />
      {/*<div className="shadow rounded-2 p-2">*/}
      {/*<Calendar*/}
      {/*  className=""*/}
      {/*  cellRender={cellRender}*/}
      {/*  // fullCellRender={cellRender}*/}
      {/*  fullscreen={false}*/}
      {/*></Calendar>*/}
      {/*</div>*/}
    </Container>
  );
};

export default Scheduler;
